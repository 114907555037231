import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setInfo } from "@/store/auth/profile";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { setFromFiledCustomSubmit } from "@/store/register";
import { BaseViewLabo } from "../labo/BaseViewLabo";
import { setLoadData } from "@/store/setting";
import { message } from "@/constants/message";
import { typeReasons } from "@/constants/typeReasons";

@injectable()
export class ReasonViewModel extends BaseViewLabo {
  constructor(@inject(TYPES.ReasonRepository) private ReasonRepository: any) {
    super();
  }

  public async fetchs(params: any): Promise<any> {
    return await this.withLoading(() => this.ReasonRepository.fetchAll(params));
  }

  public async update(id: any, param: any): Promise<any> {
    return await this.withLoading(() =>
      this.ReasonRepository.update(id, param)
    );
  }

  public async create(param: any): Promise<any> {
    return await this.withLoading(() => this.ReasonRepository.create(param));
  }

  public async delete(id: any): Promise<any> {
    return await this.withLoading(() => this.ReasonRepository.delete(id));
  }

  //sort
  public async sort(ids: ArrayBuffer): Promise<any> {
    return await this.withLoading(() => this.ReasonRepository.sort({ ids }));
  }
}

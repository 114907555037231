import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { setFromFiledCustomSubmit } from "@/store/register";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { setLoadData } from "@/store/setting";
import { message } from "@/constants/message";
import { setModal } from "@/store/global";
import { Material } from "@/helpers/material";
import { decodeUtf8, parseUtf8 } from "@/helpers/encryption";

@injectable()
export class MaterialViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.MaterialRepository) private materialRepository: any,
    @inject(TYPES.MaterialRepository) public limit: number
  ) {
    super();
    this.limit = 50;
  }

  private async profile(): Promise<any> {
    //await this.checkProfile();
    return this.hooks.getState().setting.labo;
  }

  public async fetchAll( params: any ): Promise<any> {
    return await this.withLoading( () => this.materialRepository.fetchAll({ params }) )
  }

  public async renderData(params: { [key: string]: any }, callback: Function) {
    params = Object.keys(params)
      .filter((key) => params[key])
      .reduce((cur, key) => Object.assign(cur, { [key]: params[key] }), {});

    let res: any = await this.withLoading(() =>
      this.materialRepository.fetchAll({ params })
    );

    if (res.success) {
      res.data.category_id = params.category_id || "";
      return callback(res);
    }
  }

  public async metal(): Promise<any> {
    const { metalType } = this.hooks.getState().setting;

    if (metalType.length) return metalType;

    const res: any = await this.withLoading(() =>
      this.materialRepository.fetchMetal({})
    );

    if (res.success) {
      this.hooks.dispatch(setLoadData({ ...res, type: "metalType" }));
      return res.data;
    }
  }

  public async categories(): Promise<any> {
    // const { categories } = this.hooks.getState().setting;

    //if (categories.length) return categories;

    const res: any = await this.withLoading(() =>
      this.materialRepository.fetchCategories({})
    );
    
    localStorage.setItem('categoriesTemp', JSON.stringify( res?.data.map( (val:any) => ( { id: val?.id, name: val?.name, type: val?.type  } ) ) ) )
    
    if (res.success) {
      this.hooks.dispatch(setLoadData({ ...res, type: "categories" }));
      return res.data;
    }
  }

  public async getclinics(params: {
    keyword: string;
    page: number;
    limit: number;
  }): Promise<any> {
    const res: any = await this.withLoading(() =>
      this.materialRepository.getclinics({ params })
    );
    if (res.success)
      return this.hooks.dispatch(
        setLoadData({ ...res.data, keyword: params.keyword, type: "clinics" })
      );
  }

  public async fetchOne(id: any): Promise<any> {
    const res: any = await this.withLoading(() =>
      this.materialRepository.fetch(id)
    );
    return res;
  }

  public async dispatchField(): Promise<any> {
    const { id } = this.hooks.router.query;

    let field: any = ["name", "code", "material_category_id"];

    const res: any = await this.withLoading(() =>
      this.materialRepository.fetch(id)
    );

    if (!res.success || !res.data) return this.hooks.router.push("/404");

    const { data } = res;

    Object.keys(data).forEach((key) => {
      if (data[key])
        this.hooks.dispatch(
          setFromFiledCustomSubmit({
            value: key != "ios_flg" ? data[key].toString() : data[key],
            name: key.toString(),
          })
        );
    });
  }

  public async fetch(is_reload: boolean = false): Promise<any> {
    let { material, metalType }: any = this.hooks.getState().setting;

    if (!metalType.length) await this.metal();

    const category: any = new Material(this.hooks).getCategory();

    await this.renderData(
      { category_id: category, limit: this.limit },
      (res: any) => {
        this.hooks.dispatch(setLoadData({ ...res.data, type: "material" }));
      }
    );
  }

  public async update(param: any): Promise<any> {
    const { id } = this.hooks.router.query;
    const res: any = await this.withLoading(() =>
      this.materialRepository.update(id, param)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    return res;
    // await this.fetch( true );

    // this.hooks.router.push(`/setting/material?category=${param.material_category_id}`)
  }

  public async create(param: any): Promise<any> {
    //await this.checkProfile();

    const res: any = await this.withLoading(() =>
      this.materialRepository.create(param)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    return res;
    // await this.fetch( true );
    // this.hooks.router.push(`/setting/material?category=${param.material_category_id}`)
  }

  public async delete(): Promise<any> {
    const { material_category_id } =
      this.hooks.getState().register.fromFiledCustom;

    const { id } = this.hooks.router.query;
    const res: any = await this.withLoading(() =>
      this.materialRepository.delete(id)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.delete_success, 1);
    //return res;

    // await this.fetch( true );
    // this.hooks.dispatch( setModal({ isCloseAll: true }) );
    this.hooks.router.push(`/setting/material?category=${material_category_id}`)
  }
  //sort
  public async sort(ids: any): Promise<any> {
    //await this.checkProfile();
    const { labo } = this.hooks.getState().setting;

    const res: any = await this.withLoading(() =>
      this.materialRepository.sortOrder(ids)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.branch;

    this.setMsg(res.message || message.success, 1);
    return res;
  }
}

import React, { MouseEventHandler } from "react";
import Image from "next/image";
import { Modal } from "@/components/Modal";
import { IModalCloseParams } from "@/models/Modal";
import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import { SettingEmployeeViewModel } from "@/modelviews/setting/employee";

interface IProps {
  imgAssets: any;
  handleModalClose: (
    modalCloseParams: IModalCloseParams
  ) => void;
  [key: string]: any;
}
export const ModalConfirmDeleteEmployee = ({
  imgAssets,
  modal,
  handleModalClose,
  ...props
}: IProps) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const employeeViewModel = container.resolve<SettingEmployeeViewModel>(
    SettingEmployeeViewModel
  );
  employeeViewModel.setHooks(hooks);
  
  const modalId = modal.modalIds.confirmDeleteEmployee;
  const modalList = hooks.useSelector((state: any) => state.global.modalList);
  const modalData = modalList.find((item: any) => item.modalId === modalId);
  const isShow = modalData?.isShow;
  const data = modalData?.data;
  const employeeId = data?.employeeId;  
  const handleDeleteEmployee = () => {
    if(employeeId && isShow){
      employeeViewModel.deleteEmployee(employeeId);
    }
  };
  return (
    <Modal
      isDefaultShow={false}
      isDefaultShowOnRoute={'/setting/employee/edit/[id]'}
      modalId={modal.modalIds.confirmDeleteEmployee}
      modalType={modal.modalTypes.confirmModal}
      modalSubType='modalAlert'
      modalTitle="Modal title"
    >
      <>
        <div className="modal-body">
          <div className="ico text_center">
            <Image src={imgAssets.icoExcla} alt="" />
          </div>
          <h4 className="text_center title f20">従業員の削除</h4>
          <p>
            削除すると、以下の情報がすべて失われ、ログインできなくなります。
            <br />
            削除してもよろしいですか？
          </p>
          <div className="noteInfo">
            <p>・従業員情報</p>
            <p>・ログイン情報</p>
          </div>
        </div>
        <div className="modal-footer dflex">
          <button className="btn btn-outline-secondary minw140" type="button" onClick={(e) => {
              handleModalClose({                
                modalId: modal.modalIds.confirmDeleteEmployee,
                isCloseAll: false,                
              });              
            }}>
            キャンセル
          </button>
          <button
            className="btn btn-danger minw140"
            type="button"
            onClick={(e) => {
              handleDeleteEmployee();
            }}
          >
            削除
          </button>
        </div>
      </>
    </Modal>
  );
};

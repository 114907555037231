import { createContext, useEffect, useState } from "react"; 
import { imgAssets } from "@/constants/img";
import { modalIds, modalTypes } from "@/constants/modals";
import { useRouter } from "next/router";

type Props = {
	children: React.ReactNode;
};
const initialContext = {
	imgAssets,
	modal: {
		modalIds,
		modalTypes
	}
};
export const AssetsContext = createContext(initialContext) as any;
export default function AssetsProvider({ children }: Props) {

	const { asPath, push, pathname } = useRouter()
	const [history, setHistory] = useState<string[]>([])
	
	
	const pathPrev = history.find( (url: any) => url !=  pathname ) || ''

	let isComeBack: boolean = (history?.length > 1) 

	history.forEach( (val :any) => {

					if( val.search( pathname ) < 0 )
					{
						isComeBack = false
						return isComeBack
					}
			})

	useEffect(() => {
		
		const data: any = (history?.length) ? [ history[history?.length-1], pathname ] : [ ...history, pathname ]
		setHistory(data)
	
	}, [pathname])

	
			
	return (
		<AssetsContext.Provider value={{ pathPrev, isComeBack, history, pathname }}>
			{children}
		</AssetsContext.Provider>
	)
}
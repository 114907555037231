import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface ISettingProductService {
  fetchProducts(params: any): Promise<IDataResponse<any>>;
  fetchProduct(id: number, params: any): Promise<IDataResponse<any>>;
  createProduct(data: any): Promise<IDataResponse<any>>;
  updateProduct(id: number, data: any): Promise<IDataResponse<any>>;
  deleteProduct(id: number): Promise<IDataResponse<any>>;
  fetchProductCategories(params: any): Promise<IDataResponse<any>>;
  fetchProductCategory(ids: any): Promise<IDataResponse<any>>;
  createProductCategory(ids: any, params: any): Promise<IDataResponse<any>>;
  fetchProductClasses(params: any): Promise<IDataResponse<any>>;
  crateProductProcess(
    idProdcut: number,
    params: any
  ): Promise<IDataResponse<any>>;
  updateProductProcess(id: number, params: any): Promise<IDataResponse<any>>;
  deleteProductProcess(id: number): Promise<IDataResponse<any>>;
  sort(params: any): Promise<IDataResponse<any>>;
  fetchProductClass(id: number): Promise<IDataResponse<any>>;
  fetchDefaultProcessByCate(categoryId: number): Promise<IDataResponse<any>>;
}

@injectable()
export class SettingProductService implements ISettingProductService {
  private prefix: string;
  private prefixCategory: string;
  private prefixClass: string;

  constructor() {
    this.prefix = "/labs/product";
    this.prefixCategory = "/labs/product_category";
    this.prefixClass = "/labs/product_class";
  }

  async crateProductProcess(
    idProdcut: number,
    params: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefix}/${idProdcut}/process`,
      params
    );
    return response;
  }

  async updateProductProcess(
    id: number,
    params: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(
      `${this.prefix}/process/${id}`,
      params
    );
    return response;
  }

  async deleteProductProcess(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(
      `${this.prefixClass}/process/${id}`
    );
    return response;
  }

  async fetchProducts(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async sort(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/sort`, params);
    return response;
  }

  async fetchProduct(id: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}`, {
      params: params,
    });
    return response;
  }

  async updateProduct(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async deleteProduct(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async createProduct(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async fetchProductClasses(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixClass}`, {
      params: params,
    });
    return response;
  }

  async fetchProductClass(id: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixClass}/${id}`);
    return response;
  }

  async fetchProductCategories(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}`, {
      params: params,
    });
    return response;
  }

  async fetchProductCategory(ids: number): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}/${ids}`);
    return response;
  }

  async createProductCategory(
    ids: number,
    params: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefixCategory}/${ids}`,
      params
    );
    return response;
  }

  async fetchDefaultProcessByCate(categoryId: number): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefixCategory}/${categoryId}/default-process`);
    return response;
  }

  //createProductCategory
}

export const msg = {
  validation: {
    email: {
      required: "メールアドレスは必須です。",
      invalid: "有効なメールアドレスを入力してください。",
    },
    password: {
      required: "パスワードは8〜16文字の英数字で入力してください",
      invalid: "パスワードは8〜16文字の英数字で入力してください",
      notmatch: "パスワードが一致しません。もう一度入力し直してください。",
    },
    accept: {
      required: "利用規約に同意してください",
    },
    phone: {
      required: "電話番号は必須です。",
      invalid: "有効な電話番号を入力してください。",
    },
    card: {
      required: "カード番号は必須です。",
      invalid: "有効なカード番号を入力してください。",
      cardCvc: {
        required: "セキュリティコードは必須です。",
        invalid: "有効なセキュリティコードを入力してください。",
      },
    },
  },
  register: {
    sentEmailVerify: "メールが送信されました。",
    tokenMissing: "メールを確認して、アカウントを確認してください",
  },
  auth: {
    login: {
      success: "You have successfully logged in",
      error: "メールアドレスまたはパスワードが間違っています",
    },
    logout: {
      success: "You have successfully logged out",
    },
  },

  updateSucceed: "更新が完了しました。",
  createSucceed: "登録が完了しました。",
  deleteSucceed: "削除が完了しました",
};

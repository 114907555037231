export default {
  PROCESS_CATEGORY_TYPES: {
    common: "共通工程",
    product: "製作工程",
  },

  PROCDUCT_ICON_CATEGORY_TYPES: {
    product: "技工物アイコン",
    implant: "インプラントパーツ",
  },

  INSTRUCTION_STATUS: [
    {
      type: "remake",
      name: "再製作",
    },
    {
      type: "try_on",
      name: "試適",
    },
    {
      type: "trial_complete",
      name: "試適後完成",
    },
    {
      type: "after_trial",
      name: "試適後未完成",
    },
  ],

  LAB_ORDER_STATUS: [
    {
      type: "delivered",
      name: "納品済",
      color: "#51C17D",
    },
    {
      type: "not_delivery",
      name: "未納品",
      color: "#ED5555",
    },
    {
      type: "paused",
      name: "製作中止",
      color: "rgb(254, 10, 26)",
    },
    {
      type: "canceled",
      name: "キャンセル",
      color: "rgb(121, 26, 250)",
    },
  ],

  ORDER_GROUP_TYPES: [
    {
      type: "evm_publish",
      name: "EVM登録",
    },
    {
      type: "evm_task",
      name: "EVM発行",
    },
    {
      type: "evinote",
      name: "Eviノート発行",
    },
  ],
  TEXT_DAY_JP: [ '日', '月', '火', '水', '木', '金', '土' ] //Day of Week (Sunday as 0, Saturday as 6)
};

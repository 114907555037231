
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { ILabInfo } from "@/models/Lab";
import { normalizeLabInfo } from "./nomalizeModel/normalizeLabInfo";

export interface ICheckExistInfoParams {
  type: string
  email: string
  phone: string
  clinic_id?: string
  lab_id: string
}

export interface ICheckExistInfoService {
  checkExist(params: ICheckExistInfoParams): Promise<IDataResponse<any>>;  
}

@injectable()
export class CheckExistInfoService implements ICheckExistInfoService {
  private prefix: string;
  constructor() {
    this.prefix = '/check-exist-info';
  }
  async checkExist(params: ICheckExistInfoParams): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {params});
    return response;
  }
}
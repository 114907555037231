import { store } from "@/store";
import { AnyAction } from "@reduxjs/toolkit";
import { useRouter } from "next/router";
import { createContext, useCallback, useEffect, useMemo, useRef, useState,  } from "react"; 
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from 'redux';
import { debounce } from 'lodash';

const debounceDispatch = debounce((value) => {
  store.dispatch(value)
}, 500);


export const useHook = () => {  
  const HandleScroll = (func? : any, element?: any) => useEffect(() => {   
    if(element){      
      if(element == window){            
        window.addEventListener("scroll", func);
        return () => window.removeEventListener("scroll", func);
      }else{      
        element?.addEventListener("scroll", func);
        return () => element?.removeEventListener("scroll", func);
      }   
    }    
  }, [element, func]);

  const OnRouteLeave = (func: any) => {
    const router = useRouter();
    useEffect(() => {
      router.events.on("routeChangeStart", func);
      return () => {
        router.events.off("routeChangeStart", func);
      };
    }, [func, router.events]);
  };

  return {
    dispatch: store.dispatch, 
    getState: store.getState,
    router: useRouter(),
    useSelector: useSelector,
    useEffect: useEffect,
    useState: useState,
    useMemo: useMemo,
    useCallback: useCallback,    
    debounceDispatch: debounceDispatch,
    useRef: useRef,
    handleScroll: HandleScroll,  
    onRouteLeave: OnRouteLeave,
    debounce: debounce    
  }
}
export type TContextHook = ReturnType<typeof useHook>;
export const HookContext = createContext({});


import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";



@injectable()
export class VariationService  {
  private prefix: any;

  constructor() {
    this.prefix = "/labs/variation";
  }



  async fetchAll(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, params );
    return response;
  }

  async sortOrder(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/sort-order`, data);
    return response;
  }

  async fetch(id: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}`, {
      params: params,
    });
    return response;
  }

  async update(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async create(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }
}

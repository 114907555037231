import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface ISettingLabService {
  getSetting(params: any): Promise<IDataResponse<any>>;
  updateSetting(data: any): Promise<IDataResponse<any>>;
  updateSettingEmployees(data: any): Promise<IDataResponse<any>>;
  applyAllSettingEmployees(data: any): Promise<IDataResponse<any>>;
  getBranches(params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class SettingLabService implements ISettingLabService {
  private prefix: string;
  private branchPrefix: string;

  constructor() {
    this.prefix = "/labs/settings";
    this.branchPrefix = "/labs/branches";
  }

  async getSetting(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async updateSetting(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}`, data);
    return response;
  }

  async updateSettingEmployees(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/employees`, data);
    return response;
  }

  async applyAllSettingEmployees(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/employees`, data);
    return response;
  }

  async getBranches(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.branchPrefix}`, {
      params: params
    });
    return response;
  }
}

import type { ISettingEmployeeRepository } from "@repositories/setting/employee";
import { BaseViewModel } from "@/modelviews/BaseViewModel";

import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { IEmployee, IRetireEmployeeParams } from "@/models/setting/Employee";
import { setCurrentEmployee, setEmployeeList } from "@/store/setting/employee";
import { type ISettingPermissionRepository } from "@/datasource/repositories/setting/permission/permissionRepository";
import { setPermissionList } from "@/store/setting/permission";
import { SettingBranchViewModel } from "@/modelviews/setting/branch";
import { SettingDepartmentViewModel } from "@/modelviews/setting/department";
import { setFromFiledCustom } from "@/store/register";
import { message } from "@/constants/message";
import { IPagination } from "@/models/Pagination";
import { deleteCookie, setCookie } from "cookies-next";

@injectable()
export class SettingEmployeeViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.SettingEmployeeRepository)
    private settingEmployeeRepository: ISettingEmployeeRepository,
    private settingBrandViewModel: SettingBranchViewModel,
    private settingDepartmentViewModel: SettingDepartmentViewModel,
    @inject(TYPES.SettingPermissionRepository)
    private settingPermissionRepository: ISettingPermissionRepository
  ) {
    super();
  }

  async getOriginalLog(id: any): Promise<IDataResponse<IEmployee>> {
    return await this.withLoading(() => this.settingEmployeeRepository.getOriginalLog(id) );
  }

  async getEmployeeTaskActualHours(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.withLoading(() => this.settingEmployeeRepository.getEmployeeTaskActualHours(params) );
  }

  async getEmployeeTaskTimeLine(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.withLoading(() => this.settingEmployeeRepository.getEmployeeTaskTimeLine(params) );
  }

  async getEmployeeTask(params: any): Promise<IDataResponse<IEmployee>> {
    return await this.withLoading(() =>
      this.settingEmployeeRepository.getEmployeeTask(params)
    );
  }

  async updatePolicy(id: any, params: any): Promise<IDataResponse<any>> {
    return await this.withLoading(() =>
      this.settingEmployeeRepository.updatePolicy(id, params)
    );
  }
  
  async disabledEdit(id: any): Promise<any> {
    return await this.settingEmployeeRepository.disabledEdit(id);
  }
  
  async logOut(id: any): Promise<any> {
    return await this.settingEmployeeRepository.logOut(id);
  }

  async updateEmployee(
    params: any,
    id: any
  ): Promise<IDataResponse<IEmployee>> {
    let cParams = params;
    const { password, ...rest } = params;
    if (password && password.indexOf("****") !== -1) {
      cParams = rest;
    }
    
    let res = await this.withLoading(() =>
      this.settingEmployeeRepository.updateEmployee(cParams, id)
    );
    if (res.success) {
      this.setMsg( res.message || message.success, 1);

    } else {
      this.setMsg(res.message || message.error, -1);
    }
    return res;
  }

  async deleteEmployee(id: any): Promise<IDataResponse<any>> {
    let res = await this.withLoading(() =>
      this.settingEmployeeRepository.deleteEmployee(id)
    );
    if(res.success){
      this.hooks.dispatch( setFromFiledCustom({}) )
      this.setMsg(res.message || message.success, 1);
      this.timeoutRedirect(500, "/setting/employee");
      this.closeAllModal();
      
    } else {
      this.setMsg(res.message || message.error, -1);
    }
    return res;
  }
  async retireEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    let res = await this.withLoading(() =>
      this.settingEmployeeRepository.retireEmployee(params, id)
    );
    if (res.success) {
      this.setMsg(message.success, 1);
      // this.timeoutRedirect(500, "/setting/employee/?retired=true");
      this.closeAllModal();
      
    } else {
      this.setMsg(res.message || message.error, -1);
    }
    return res;
  }

  async recoverEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    let res = await this.withLoading(() =>
      this.settingEmployeeRepository.recoverEmployee(params, id)
    );
    if (res.success) {
      this.setMsg(message.success, 1);
      // this.timeoutRedirect(500, "/setting/employee");
      this.closeAllModal();
      
    } else {
      this.setMsg(res.message || message.error, -1);
    }
    return res;
  }

  async getAllPermission(params?: any): Promise<IDataResponse<any>> {
    let res = await this.settingPermissionRepository.getAllPermission(params);
    if (res.success && res.data) {
      this.hooks.dispatch(setPermissionList(res.data));
    }
    return res;
  }
  async getEssensialFields(labId: any): Promise<any> {
    
    this.settingBrandViewModel.setHooks(this.hooks);
    this.settingDepartmentViewModel.setHooks(this.hooks);
 
    await Promise.all([
        // this.settingBrandViewModel.getBranchList(),
        this.settingDepartmentViewModel.getDepartmentList(labId),
        this.getAllPermission(),
    ]);
  }
  async getEmployee(id: any, labId: any): Promise<IDataResponse<IEmployee>> {
    this.setLoading(true);
    await this.getEssensialFields(labId);
    this.setLoading(false);
    let res = await this.settingEmployeeRepository.getEmployee(id);
    if (res.success && res.data) {
      this.hooks.dispatch(setCurrentEmployee(res.data));
    } else {
      this.setMsg(res.message || "従業員なし", -1);
      this.timeoutRedirect(2500, "/setting/employee");
    }
    return res;
  }
  async createEmployee(params: any): Promise<IDataResponse<IEmployee>> {
    let res = await this.withLoading(() =>
      this.settingEmployeeRepository.createEmployee(params)
    );
    if (res.success && res.data) {
      this.hooks.dispatch( setFromFiledCustom({}) )
      this.setMsg(message.success, 1);
      this.timeoutRedirect(1500, "/setting/employee");
    } else {
      this.setMsg(res.message || message.failed, -1);
    }
    return res;
  }
  async getAllEmployee(params?: any, isLoadMore?: boolean, isLoading: boolean = true): Promise<IDataResponse<{data:IEmployee[]; pagination: IPagination}>> {
    params = { ...params, includes: 'Lab' }
    let res: any = isLoading ? await this.withLoading(() => this.settingEmployeeRepository.getAllEmployee(params) ) 
                          : await this.settingEmployeeRepository.getAllEmployee(params);

    if(res.success && res.data){
      let listEmployee: any = this.hooks.getState().settingEmployee.employeeList || {};

      listEmployee = {  
                          data: isLoadMore ? [ ...listEmployee.data, ...res.data.data ] : [ ...res.data.data ],
                          pagination: { ...res.data.pagination }
                                        // current_page: res?.data?.current_page,  
                                        // last_page: res?.data?.last_page,  
                                        // total: res?.data?.total,  
                                        
                      };
      
      
      this.hooks.dispatch(setEmployeeList(listEmployee));
    }
    return res;
  }

  async fetchAllEmployee(params: any, isLoading:boolean = true): Promise<IDataResponse<any>> {
    return await ( isLoading ? this.withLoading(() => this.settingEmployeeRepository.getAllEmployee(params)) : this.settingEmployeeRepository.getAllEmployee(params) );
  }

  async getDepartmentEmployee(params: any): Promise<IDataResponse<any>> {
    let res = await this.settingBrandViewModel.getDepartmentEmployee(params);
    return res;
  }

  async updateEmployeeTargetPoint(
    id: any,
    data: any
  ): Promise<IDataResponse<any>> {
    let res = await this.settingEmployeeRepository.updateEmployeeTargetPoint(
      id,
      data
    );
    return res;
  }
}

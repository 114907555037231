import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { AppDispatch, RootState } from '../store';
import React, { useEffect } from 'react';
export const Loading = () => {
  const {isLoading} = useSelector((state: any) => state.global);
  // clear isLoading
  const dispatch = useDispatch<AppDispatch>();  
  useEffect(() => {
    const timer = setTimeout(() => {
      // dispatch({type: 'global/setLoading', payload: false});
    }, 8000);
    return () => clearTimeout(timer);
  }, [isLoading])  
  const dataComponent = () => 
    <div className={`loading-wrapper general_loading`}>
      <div className="loading-content">
        <svg xmlns="http://www.w3.org/2000/svg" width="70.026" height="72" viewBox="0 0 70.026 72" className="logo-white rotate">
          <g id="Group_242" data-name="Group 242" transform="translate(-42.922 -969.332)">
            <path id="Path_109" data-name="Path 109" d="M52.411,979.37a1.68,1.68,0,0,0,1.311.632H74.054a1.677,1.677,0,0,0,1.679-1.676,1.756,1.756,0,0,0-.079-.508L75.1,975.4a26.758,26.758,0,0,0-22.425,0l-.584,2.558A1.677,1.677,0,0,0,52.411,979.37Z" transform="translate(14.048 5.547)" fill="#52A8FF"/>
            <path id="Path_110" data-name="Path 110" d="M73.371,977.269l-1.516-6.632a1.678,1.678,0,0,0-1.633-1.306H55.772a1.681,1.681,0,0,0-1.636,1.306l-1.514,6.632a29.951,29.951,0,0,1,20.749,0Z" transform="translate(14.937 0)" fill="#52A8FF"/>
            <path id="Path_111" data-name="Path 111" d="M52.622,994.553l1.514,6.637a1.682,1.682,0,0,0,1.636,1.3h14.45a1.679,1.679,0,0,0,1.633-1.3l1.516-6.634a29.892,29.892,0,0,1-20.749,0Z" transform="translate(14.937 38.839)" fill="#52A8FF"/>
            <path id="Path_112" data-name="Path 112" d="M75.733,992.968a1.676,1.676,0,0,0-1.679-1.674H53.722a1.676,1.676,0,0,0-1.636,2.047l.584,2.555a26.72,26.72,0,0,0,22.425,0l.559-2.418A1.788,1.788,0,0,0,75.733,992.968Z" transform="translate(14.048 33.82)" fill="#52A8FF"/>
            <path id="Path_113" data-name="Path 113" d="M58.834,975.711l-6.52-2.009a1.673,1.673,0,0,0-1.943.762l-7.224,12.514a1.672,1.672,0,0,0,.31,2.065l4.991,4.635A29.926,29.926,0,0,1,58.834,975.711Z" transform="translate(0 6.613)" fill="#52A8FF"/>
            <path id="Path_114" data-name="Path 114" d="M49.5,996.516a1.665,1.665,0,0,0,1.2-.818l10.167-17.607v0a1.673,1.673,0,0,0-.612-2.289,1.579,1.579,0,0,0-.48-.185l-2.37-.731A26.853,26.853,0,0,0,46.183,994.3l1.923,1.786A1.681,1.681,0,0,0,49.5,996.516Z" transform="translate(5.021 8.548)" fill="#52A8FF"/>
            <path id="Path_115" data-name="Path 115" d="M74.616,985.491a29.927,29.927,0,0,1-10.391,17.97l6.523,2.007a1.672,1.672,0,0,0,1.946-.765l7.224-12.509a1.679,1.679,0,0,0-.31-2.068Z" transform="translate(32.805 24.884)" fill="#52A8FF"/>
            <path id="Path_116" data-name="Path 116" d="M76.27,985.847l-1.829-1.7a1.776,1.776,0,0,0-.4-.323,1.677,1.677,0,0,0-2.291.615l-.005.005-10.162,17.6a1.677,1.677,0,0,0,.958,2.441l2.492.767A26.867,26.867,0,0,0,76.27,985.847Z" transform="translate(28.397 21.976)" fill="#52A8FF"/>
            <path id="Path_117" data-name="Path 117" d="M48.45,985.492l-4.991,4.633a1.672,1.672,0,0,0-.31,2.068l7.224,12.509a1.672,1.672,0,0,0,1.946.765l6.523-2.007A29.926,29.926,0,0,1,48.45,985.492Z" transform="translate(0.001 24.885)" fill="#52A8FF"/>
            <path id="Path_118" data-name="Path 118" d="M60.973,1003.5a1.656,1.656,0,0,0-.107-1.45L50.7,984.447l0-.005a1.675,1.675,0,0,0-2.288-.615,1.807,1.807,0,0,0-.4.323l-1.826,1.7a26.86,26.86,0,0,0,11.229,19.413l2.5-.767A1.671,1.671,0,0,0,60.973,1003.5Z" transform="translate(5.021 21.976)" fill="#52A8FF"/>
            <path id="Path_119" data-name="Path 119" d="M74.611,993.678l4.994-4.635a1.674,1.674,0,0,0,.307-2.067l-7.224-12.512a1.671,1.671,0,0,0-1.943-.762l-6.518,2.009A29.918,29.918,0,0,1,74.611,993.678Z" transform="translate(32.81 6.613)" fill="#52A8FF"/>
            <path id="Path_120" data-name="Path 120" d="M62.2,975.8a1.673,1.673,0,0,0-.617,2.289l.005,0L71.754,995.7a1.674,1.674,0,0,0,2.588.389l1.928-1.786a26.85,26.85,0,0,0-11.227-19.418l-2.365.731A1.458,1.458,0,0,0,62.2,975.8Z" transform="translate(28.397 8.548)" fill="#52A8FF"/>
          </g>
        </svg>
      </div>
    </div>
  
  return (
    isLoading ? dataComponent() : <> </>
  )
}
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import {  setInfo  } from '@/store/auth/profile'
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { setFromFiledCustomSubmit } from "@/store/register";
import { BaseViewLabo } from "../labo/BaseViewLabo";
import { setLoadData  } from "@/store/setting";
import { message } from "@/constants/message";
import { setModal } from "@/store/global";
import { Material } from "@/helpers/material";

@injectable()
export class VariationsViewModel extends BaseViewLabo {

  constructor(
    @inject(TYPES.VariationService) public variationRepo: any,
  ) {
    super();
  }



  public async  update( param: any ) : Promise<any>
  {


    const { id } = this.hooks.router.query;

    const res: any = await  this.withLoading( () => this.variationRepo.update( id, param ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
   //VariationRepository
  }

  public async  create( param: any  ) : Promise<any>
  {
    await this.checkProfile();

    const res: any = await this.withLoading( () => this.variationRepo.create( param ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
    return res;
    ////VariationRepository
    // this.hooks.router.push(`/setting/material/implant/variations?manufacture_id=${res.data.id}&category=${param.material_category_id}`)

  }

  public async  delete( ) : Promise<any>
  {
    const { material_category_id } = this.hooks.getState().register.fromFiledCustom;

    const { id } = this.hooks.router.query;
    const res: any = await this.withLoading( () => this.variationRepo.delete( id ) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    this.setMsg(res.message || message.success, 1);
   //VariationRepository
    this.hooks.dispatch( setModal({ isCloseAll: true }) );
    this.hooks.router.push(`/setting/material?category=${material_category_id}`)

  }
  //sort
  public async  sort( ids: any  ) : Promise<any>
  {
    await this.checkProfile();
    const { labo } = this.hooks.getState().setting;

    const res: any = await this.withLoading( () => this.variationRepo.sortOrder(ids) );

    if ( ! res.success)
    {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items , current_page, last_page } = this.hooks.getState().setting.branch;
    //await this.renderData( );
    this.setMsg(res.message || message.success, 1);
    return//VariationRepository
  }

}


import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { ILabInfo } from "@/models/Lab";
import { normalizeLabInfo } from "./nomalizeModel/normalizeLabInfo";
export interface IListLab {
  total: number,
  current_page: number,
  to: number,
  last_page: number,
  per_page: number,
  data: ILabInfo[],
};

export interface ISearchLabParams {
  keyword: string;
  limit: number;
}

export interface ISearchLabService {
  searchLab(params: ISearchLabParams): Promise<IDataResponse<IListLab>>;  
}

@injectable()
export class SearchLabService implements ISearchLabService {
  private prefix: string;
  constructor() {
    this.prefix = '/labs';
  }
  async searchLab(params: ISearchLabParams): Promise<IDataResponse<IListLab>> {
    const response = await axiosService.get(`${this.prefix}`, {params});
    if(response.data?.data){
      const resNormalize = response.data?.data.map((item: any) => {
        return normalizeLabInfo(item)
      });
      response.data.data = resNormalize;
    }
    return response;
  }
}

export const getErrorMsg = (res: any) => {
  let errors = res.errors;  
  if(errors){
    let keys = Object.keys(errors);
    let msg = '';
    keys.forEach((key) => {
      msg += errors[key] + '\n';
    });
    return msg;
  }else{
    return res.message;
  }
}
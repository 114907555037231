import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { setDepartmentList } from "@/store/setting/department";

@injectable()
export class SettingDepartmentViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.DepartmentRepository)
    private settingDepartmentRepo: any
  ) {
    super();
  }
  async getDepartmentList(labId: any): Promise<IDataResponse<any>> {
    let res = await this.settingDepartmentRepo.fetchs({ limit: -1 });
    if (res.success && res.data) {
      this.hooks.dispatch(setDepartmentList(res.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }

}
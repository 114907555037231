import {
  configureStore,
  ThunkAction,
  Action,
  createSerializableStateInvariantMiddleware,
  isPlain,
} from "@reduxjs/toolkit";
import globalSlice from "./global";
import { authSlice } from "./auth/index";
import { loginSlice } from "./auth/login";
import { profileSlice } from "./auth/profile";
import { registerSlice } from "./register";
import { prefcitySlice } from "./prefcity";
import { cardSlice } from "./card/card";
import { settingSlice } from "./setting";
import { settingBranchSlice } from "./setting/branch";
import { settingDepartmentSlice } from "./setting/department";
import { settingEmployeeSlice } from "./setting/employee";
import { settingProcessSlice } from "./setting/process";
import { settingIconSlice } from "./setting/icon";
import { settingProductSlice } from "./setting/product";
import { settingPermissionSlice } from "./setting/permission";
import { settingLabSlice } from "./setting/lab";
import { orderManageSlice } from "./order/manage";
import { orderProcessSlice } from "./order/process";
import { orderPrintSlice } from "./order/print";
import { orderSlice } from "./order/index";
import { taskSlice } from "./task/index";
import { orderOwners } from "./order/owners";
import { orderHistorySlice } from "./order/history";
import { statisticStaffPointSlice } from "./statistic/staffPoint";
import { statisticClinicSlice } from "./statistic/clinic";
import { statisticStaffSlice } from "./statistic/staff";
import { statisticDepartmentSlice } from "./statistic/department";
import { statisticWeekPointSlice } from "./statistic/weekPoint";

import logger from "redux-logger";
import { orderGroupSlice } from "./order/group";

export const store = configureStore({
  reducer: {
    [globalSlice.name]: globalSlice.reducer,
    [loginSlice.name]: loginSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [registerSlice.name]: registerSlice.reducer,
    [prefcitySlice.name]: prefcitySlice.reducer,
    [cardSlice.name]: cardSlice.reducer,
    [profileSlice.name]: profileSlice.reducer,
    [settingSlice.name]: settingSlice.reducer,
    [settingBranchSlice.name]: settingBranchSlice.reducer,
    [settingDepartmentSlice.name]: settingDepartmentSlice.reducer,
    [settingEmployeeSlice.name]: settingEmployeeSlice.reducer,
    [settingProcessSlice.name]: settingProcessSlice.reducer,
    [settingPermissionSlice.name]: settingPermissionSlice.reducer,
    [settingIconSlice.name]: settingIconSlice.reducer,
    [settingLabSlice.name]: settingLabSlice.reducer,
    [settingProductSlice.name]: settingProductSlice.reducer,
    [orderSlice.name]: orderSlice.reducer,
    [taskSlice.name]: taskSlice.reducer,
    [orderProcessSlice.name]: orderProcessSlice.reducer,
    [orderPrintSlice.name]: orderPrintSlice.reducer,
    [orderManageSlice.name]: orderManageSlice.reducer,
    [orderGroupSlice.name]: orderGroupSlice.reducer,
    [orderOwners.name]: orderOwners.reducer,
    [orderHistorySlice.name]: orderHistorySlice.reducer,
    [statisticStaffPointSlice.name]: statisticStaffPointSlice.reducer,
    [statisticClinicSlice.name]: statisticClinicSlice.reducer,
    [statisticStaffSlice.name]: statisticStaffSlice.reducer,
    [statisticDepartmentSlice.name]: statisticDepartmentSlice.reducer,
    [statisticWeekPointSlice.name]: statisticWeekPointSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
   //.concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

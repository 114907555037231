import React from "react";
import { store } from "@/store";
import Router from "next/router";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not

    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    
    localStorage.setItem("error", error);
    localStorage.setItem("errorInfo", JSON.stringify(errorInfo));

    const { pathname } = Router;


    // call api here
  }
  render() {
    // Check if the error is thrown
    let getState: any = this.state;
    let getProps: any = this.props;
    if (getState && getState.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {/* <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button> */}
        </div>
      );
    }

    // Return children components in case of no error
    return getProps.children;
  }
}

export default ErrorBoundary;

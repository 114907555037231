
import axiosService from "@services/axiosService";
import { IUserCredentials, IAuthToken, Profile } from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { injectable } from "inversify";
import { IDepartmentEmployee } from "@/models/setting/Department";
import { normalizeDepartmentEmployee } from "@services/nomalizeModel/normalizeDepartmentEmployee";


@injectable()
export class StatisticClinicService {

  private prefix: string;

  constructor() {
    this.prefix = '/labs/statistic';
  }

  async fetchAll( params :any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/remake-clinic`,{ params: params });
  }

  async fetchOne( id: any, params :any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/remake-clinic/${id}`,{ params: params });
  }

  async fetchStaffs( params :any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/staff-remake-by-reason`,{ params: params });
  }

}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IState {
  modals: any;
  icons: any;
  iconCategories: any;
  allIconCategories: Array<any>;
  currentIconCategory: any;
  icon: any;
}

const initialState: IState = {
  icons: [],
  icon: null,

  iconCategories: {
    data: [],
  },
  allIconCategories: [],

  currentIconCategory: {},

  modals: {
    createCategory: false,
  },
};

export const settingIconSlice = createSlice({
  name: "settingIcon",
  initialState,
  reducers: {
    resetIcons(state) {
      state.icons = [];
    },

    reset(state) {
      state.allIconCategories = [...initialState.allIconCategories];
      state.currentIconCategory = { ...initialState.currentIconCategory };
      state.icons = [...initialState.icons];
      state.icon = { ...initialState.icons };
    },

    fetchIcons(state, action) {
      let data = action.payload;
      state.icons = data;
    },

    fetchIcon(state, action) {
      let icon = state.icon;
      let data = action.payload;
      state.icon = { ...data };
    },

    deleteIcon(state, action) {
      let icons = state.icons;
      let { id } = action.payload;
      let findIndex = icons.data.findIndex((item: any) => item.id === id);
      if (findIndex !== -1) {
        icons.data.splice(findIndex, 1);
      }
      state.icons = icons;
    },

    fetchCurrentCategory(state, action) {
      state.currentIconCategory = action.payload;
    },
    fetchIconCategories(state, action) {
      let iconCategories = state.iconCategories;
      let data = action.payload;
      iconCategories = { ...data };
      state.iconCategories = iconCategories;
    },

    fetchAllIconCategories(state, action) {
      let allIconCategories = state.allIconCategories;
      let data = action.payload;
      allIconCategories = [...data];
      state.allIconCategories = allIconCategories;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
  },
  extraReducers: (builder) => {},
});

export default settingIconSlice;
export const settingIconReducer = settingIconSlice.reducer;
export const settingIconSliceAction = settingIconSlice.actions;

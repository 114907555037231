import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { BaseViewModel } from "../BaseViewModel";


@injectable()
export class StatisticClinicViewModel extends BaseViewModel {

  constructor(
    @inject(TYPES.StatisticClinicRepository) private statisticclinic: any,
  )
  {
    super();
  }

  async fetchStaffs( param : any ): Promise<any>
  {
    return await this.statisticclinic.fetchStaffs( param )
    //this.withLoading( () =>    );
  }

  async fetchAll( param : any ): Promise<any>
  {
    return await this.withLoading( () =>  this.statisticclinic.fetchAll( param )  );
  }

  async fetchOne( id: any, param : any ): Promise<any>
  {
    return await this.withLoading( () =>  this.statisticclinic.fetchOne( id, param )  );
  }

}

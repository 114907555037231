import { type TContextHook } from "@/context/hook";
import { setLoading, setMsg } from "@/store/global";
import { Container, inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import "reflect-metadata";
import { BaseViewModel } from "@/modelviews/BaseViewModel";


@injectable()
export class BaseViewLabo extends  BaseViewModel
{

    @inject(TYPES.AuthRepository) private authRepository: any;

    public async checkProfile() {

    }

}
import { convertTzTo, range } from "@/helpers";
import dayjs from "dayjs";
import _, { ceil } from "lodash";
import moment from "moment";

export const getFilterDate = (filter: any, type: string) => {
  let currentYear = filter.year;
  let currentMonth = filter.month;
  let currentDay = filter.day;
  let from = filter.from;
  let to = filter.to;
  let response: any = {};

  switch (type) {
    case "year":
      response.year = currentYear;
      break;
    case "month":
      response.month = `${currentYear}-${currentMonth}`;
      break;
    case "day":
      response.date = `${currentYear}-${currentMonth}-${currentDay}`;
      break;
    case "half_year":
      response.from = from?.format("YYYY-MM-DD");
      response.to = to?.format("YYYY-MM-DD");
      break;
    case "week":
      response.year = moment(new Date(`${currentYear}-${currentMonth}-${currentDay}`)).clone().weekday(5).year();
      response.week = filter.week;
      break;
  }
  return response;
};

// workingTime: minutes
export const calcTime = (time: any) => {
  let sign = "";
  if (time < 0) {
    time = Math.abs(time);
    sign = "-";
  }

  let hour = Math.floor(parseFloat(time) / 60);
  let minute = Math.floor(parseFloat(time) % 60);
  let second = Math.round((parseFloat(time) - (+hour * 60) - +minute) * 60);
  return {
    sign,
    hour,
    minute,
    second,
  };
};

export const sort = (soryBy: string, sortType: string, data: Array<any>) => {
  if (soryBy === "rank") {
    data = _.orderBy(data, soryBy, sortType === "desc" ? ["asc"] : ["desc"]);
    return data;
  }

  if (sortType === "asc") {
    data.sort(
      (a: any, b: any) => parseFloat(a[soryBy]) - parseFloat(b[soryBy])
    );
  } else if (sortType === "desc") {
    data.sort(
      (a: any, b: any) => parseFloat(b[soryBy]) - parseFloat(a[soryBy])
    );
  }

  return data;
};

export const getRankProcess = (process: any, avgPointInHour: any = null) => {
  let alias = "";
  let ranks = process.RankPoint?.length > 0 ? process.RankPoint : [];

  if (avgPointInHour === null) {
    return "";
  }

  if (process.setting_rank_flg) {
    for (let i = 0; i < ranks.length; i++) {
      let minPoint = ranks[i].min_point ? parseFloat(ranks[i].min_point) : 0;
      let maxPoint = ranks[i].max_point ? parseFloat(ranks[i].max_point) : 0;

      if (ranks[i].name === "A" && avgPointInHour >= minPoint) {
        alias = ranks[i].name;
        return alias;
      } else if (avgPointInHour >= minPoint && avgPointInHour <= maxPoint) {
        alias = ranks[i].name;
        return alias;
      }
    }
  }
  return alias;
};

export const getRankColor = (rankAlias: string) => {
  switch(rankAlias) {
    case "A":
      return "#518CED";
    case "B":
      return "#51C17D";
    case "C":
      return "#F0932B";
    case "D":
    case "E":
      return "#ED5555";
    default: 
      return ""
  }
}

export const mappingStaffPoint = (statisticData: any, process: any = null) => {
  return {
    id: statisticData.id,
    workingDay: statisticData.total_login_date,
    workingTime: {
      hour: calcTime(statisticData.total_time).hour,
      minute: calcTime(statisticData.total_time).minute,
      second: calcTime(statisticData.total_time).second,
    },
    workingTargetTime: {
      hour: calcTime(statisticData.total_time_target || 0).hour,
      minute: calcTime(statisticData.total_time_target || 0).minute,
      second: calcTime(statisticData.total_time_target || 0).second,
    },
    User: statisticData?.User,
    Department: statisticData.Department,
    Branch: statisticData.Branch,
    Process: statisticData.Process,
    targetPoint: +statisticData.target_point || 0,
    totalPoint: +statisticData.total_point || 0,
    totalTargetTime: statisticData.total_time_target || 0,
    totalTime: statisticData.total_time || 0,
    employeeBarcode: statisticData.employee_barcode,
    nameMei: statisticData.name_mei,
    nameSei: statisticData.name_sei,
    userRole: statisticData.user_role,
    targetDay: statisticData.target_day || 0,
    differenceWorkingTime:
      statisticData.total_time - (statisticData.total_time_target || 0),
    differenceWorkingDay:
      statisticData.total_login_date - statisticData.target_day,
    differencePoint:
      +statisticData.total_point - (statisticData.target_point || 0),
    avgDay:
      +statisticData.total_login_date === 0
        ? 0
        : +statisticData.total_point / statisticData.total_login_date,
    avgTime:
      +statisticData.total_time === 0
        ? 0
        : +statisticData.total_point / (statisticData.total_time / 60),
    avgProduct:
      +statisticData.total_product === 0
        ? 0
        : +statisticData.total_time / statisticData.total_product,
    avgProductTarget:
      +statisticData.total_product === 0
        ? 0
        : parseInt((+statisticData.total_product_target_time / statisticData.total_product).toFixed(0)),
    rank: process ? getRankProcess(process, 
      statisticData.total_time == 0 ? null : +statisticData.total_point / (statisticData.total_time / 60)) 
    : null,
  };
};

export const getListMonth = (filter: any) => {
  let listMonth: any = [];
  if (filter.type === "half_year") {
    for (let i = 0; i <= 5; i++) {
      let month = parseInt(filter.month) + i;
      if (month > 12) month -= 12;
      listMonth.push(month);
    }
  } else if (filter.type === "year") {
    listMonth = range(1, 12);
  }
  return listMonth;
};

export const handleHalfYear = (
  data: { year: number; month: number; day: number },
  settingDay: string | null = null,
  format: string | null = "YYYY-MM-DD"
) => {
  //dayjs
  //let dataReturn:any = {}

  const { year, month, day } = data;

  let from: any;
  let to: any;

  if (!settingDay) {
    from = dayjs(new Date(`${year}-${month}-${day}`));
    to = dayjs(new Date(`${year}-${month}-${day}`)).add(5, "month");
  } else {
    switch (settingDay) {
      case "end_of_month":
        from = dayjs(new Date(`${year}-${month}-01`)).startOf("month");
        to = dayjs(new Date(`${year}-${month}-01`)).add(5, "month").endOf("month");
        break;
      default:
        from = dayjs(new Date(`${year}-${month}-${Number(settingDay)}`)).add(1,"day").subtract(1, "month");
        to = dayjs(new Date(`${year}-${month}-${Number(settingDay)}`)).add(5,"month" );
        break;
    }
  }

  //from = from.subtract(1, "month");

  return {
    from: !format ? from : from.format(format),
    to: !format ? to : to.format(format),
  };
};

/*
  shift_deadline: "end_of_month" | number
  type: "year" | "month"
  date: YYYY-MM-DD,
  year: YYYY,
  month: YYYY-MM
*/
export const handleFromTo = (params: any) => {
  const type = params.type ?? "month";
  if(!params.type) {
    return 
  }
  let from = null;
  let to = null;
  if (type == 'month') {
    if (params.month) {
      let temp = moment(params.month + '-01');
      if (params.shift_deadline == 'end_of_month') {
        from = temp.format('YYYY-MM-DD');
        to = moment(from).endOf('month').format('YYYY-MM-DD');
      } else {
        to = moment(temp.format('YYYY-MM') + '-' + params.shift_deadline).format('YYYY-MM-DD');
        from = moment(to).add(-1, 'months').add(1, 'days').format('YYYY-MM-DD');
      }
    }
  }
  if (type == 'year') {
    let temp = moment(params.year + '-12-31');
    if (params.shift_deadline == 'end_of_month') {
      from = temp.startOf('year').format('YYYY-MM-DD');
      to = moment(from).endOf('year').format('YYYY-MM-DD');
    } else {
      to = moment(temp.format('YYYY-MM') + '-' + params.shift_deadline).format('YYYY-MM-DD');
      from = moment(to).add(-1, 'year').add(1, 'days').format('YYYY-MM-DD');
    }
  }
  if (type == 'day') {
    from = `${params.date ?? moment().format('YYYY-MM-DD')}`;
    to = from;
  }
  if (type == 'half_year') {
    from = params.from;
    to = params.to;
  }
  return {
    from: moment(from),
    to: moment(to)
  }
}


export const getResultValue = (total: any, target: any, isNegativeSign : any = null) => {
  total = !total ? 0 : total;
  target = !target ? 0 : target;

  let sign = "";
  let value = total - target;
  let textClass = "clred";
  if (value > 0) {
    textClass = "clmain"
    sign = "+";
  } else if (value == 0) {
    sign = "±";
    textClass = ""
  }

  if(isNegativeSign && value < 0)
  {
    sign = "-"
  }

  return { sign, value:  parseFloat(value.toFixed(2)), class: textClass };
};

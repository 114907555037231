import { IEmployee } from "@/models/setting/Employee"
import { IPagination } from "@/models/Pagination"

export const normalizeEmployeeList = (data: any): {
  data: IEmployee[],
  pagination: IPagination
} => {
  return {
    data: data.data,
    pagination: {
      last_page: data.last_page,
      current_page: data.current_page,
      to: data.to,
      from: data.from,
      per_page: data.per_page,
      total: data.total      
    }
  }
}
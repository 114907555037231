import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mappingStaffPoint, sort } from "@/helpers/stastistic";

interface IState {
  filter: any;

  statisticDepartment: any;
  sort: any;
  sortList: any;
}

const initialState: IState = {
  statisticDepartment: {
    from: null,
    to: null,
    timeData: [],
    targetPoint: null,
    statisticData: [],
  },

  sort: {
    sort_by: "totalPoint",
    sort_type: "desc"
  },

  sortList: {
    totalPoint_desc: "ポイントが高い順",
    totalPoint_asc: "ポイントが低い順",
    differencePoint_desc: "差分プラスが大きい順",
    differencePoint_asc: "差分マイナスが大きい順",
    avgDay_desc: "1日平均が高い順",
    avgDay_asc: "1日平均が低い順",
    avgTime_desc: "1時間平均が高い順",
    avgTime_asc: "1時間平均が低い順",
    totalTime_desc: "業務時間が長い順",
    totalTime_asc: "業務時間が短い順",
    workingDay_desc: "勤務日数が多い順",
    workingDay_asc: "勤務日数が少ない順",
    id_desc: "従業員番号が大きい順",
    id_asc: "従業員番号が小さい順",
  },

  filter: {
    type: "year", // month, year, date, half_year
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    day: new Date().getDate(),
    branch: "all",
    department: null,
    sort: "totalPoint_desc",

    modals: {
      showSort: false,
    },
  },
};

export const statisticDepartmentSlice = createSlice({
  name: "statisticDepartment",
  initialState,
  reducers: {
    resetStatisticDepartment(state) {
      state.statisticDepartment = { ...initialState.statisticDepartment };
    },

    updateSort(state, action) {
      state.sort = action.payload;
    },

    fetchStatisticDepartment(state, action) {
      const { time_data, User, target_point, from, to } = action.payload;
      let employees = [];
      for (let i = 0; i < User.length; i++) {
        employees[i] = mappingStaffPoint(User[i]);
      }

      // handle sort
      let { sort_by, sort_type } = state.sort;
      let sortEmployees = sort(sort_by, sort_type, employees);

      state.statisticDepartment = {
        ...action.payload,
        from: from,
        to: to,
        timeData: time_data,
        statisticData: sortEmployees,
        targetPoint: target_point,
      };
    },

    updateStatisticDepartment(state, action) {
      const { timeData, statisticData, targetPoint } = action.payload;
      state.statisticDepartment = {
        from: state.statisticDepartment.from,
        to: state.statisticDepartment.to,
        timeData: timeData,
        statisticData: statisticData,
        targetPoint: targetPoint,
      };
    },

    
    updateFilter(state, action) {
      let filter = { ...state.filter };
      let data = action.payload;

      for (let prop in data) {
        filter[prop] = data[prop]
      }
      state.filter = filter;
    },

    resetFilter(state) {
      state.filter = {...initialState.filter}
      state.sortList = {...initialState.sortList}
    },
    
    setFilter(state, action) {
      let { key, val } = action.payload;
      let filter = { ...state.filter };
      // handle modals
      if (filter.modals.hasOwnProperty(key)) {
        for (let prop in filter.modals) {
          filter.modals[prop] = false;
        }
        filter.modals[key] = val;
      } else {
        // handle prop
        filter[key] = val;
      }

      state.filter = filter;
    },

    resetModal(state)
    {
      let initFilter = { ...initialState.filter };
      state.filter.modals = {...initFilter.modals}
    }
  },
});

export default statisticDepartmentSlice;
export const orderGroupReducer = statisticDepartmentSlice.reducer;
export const statisticDepartmentSliceAction = statisticDepartmentSlice.actions;

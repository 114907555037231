import { ILabInfo } from "@/models/Lab";

export const normalizeLabInfo = (item: any): ILabInfo => {
  const {
    id,
    clinic_select_flg,
    sort_order,
    lab_parent_flg,
    parent_lab_id,
    lab_corp_kbn,
    lab_name,
    lab_name_f,
    lab_zip,
    lab_pref,
    lab_address,
    lab_building,
    lab_phone,
    lab_fax,
    lab_email,
    evinote_status,
    evinote_printer_number,
  } = item;
  const City = {
    id: item.City?.id,
    pref_id: item.City?.pref_id,
    name: item.City?.name,
  };
  const Pref = {
    id: item.Pref?.id,
    name: item.Pref?.name,
    region_id: item.Pref?.region_id,
  };
  return {
    id,
    clinic_select_flg,
    sort_order,
    lab_parent_flg,
    parent_lab_id,
    lab_corp_kbn,
    lab_name,
    lab_name_f,
    lab_zip,
    lab_pref,
    lab_address,
    lab_building,
    lab_phone,
    lab_fax,
    lab_email,
    evinote_status,
    evinote_printer_number,
    City,
    Pref,
  };
};

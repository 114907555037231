import { inject } from "inversify";
import { msg } from "../msg";
import { log } from "console";
interface ValidationResult {
  isValid: boolean;
  message: string;
}

interface IValidation {
  [key: string]: (...args: any[]) => ValidationResult;
}
interface ValidateField {
  [key: string]: { isValid?: boolean };
}

export const allFieldsValidExclude = (
  validateField: ValidateField,
  allFields: string[],
  fieldName: string[]
): boolean => {
  const newValidateField = Object.assign({}, validateField);
  allFields.forEach((field) => {
    if (!newValidateField[field]) {
      newValidateField[field] = { isValid: false };
    }
  });

  const keys = Object.keys(newValidateField);
  if (keys.length === 0) {
    return false;
  }
  //get all keys from object
  const fieldsToExclude = keys.reduce((acc: any[], current: string) => {
    if (!fieldName.includes(current)) {
      acc.push(newValidateField[current]);
    }
    return acc;
  }, []);
  //check if all keys are valid
  return fieldsToExclude.every((field) => field.isValid);
};

export default class FormValidation implements IValidation {
  [key: string]: any;

  constructor(
    private variation: any = { required: false },
    public respone: any = { isValid: true, message: "" }
  ) {}

  required(value: any, name?: string): ValidationResult {
    
    this.variation = { required: true };

    const isValid = value !== null && value !== undefined && value !== "" && value !== "**********";
    
    const rest: any = { isValid, message: isValid ? "" : name + "は必須です。", };

    this.respone = rest;
    return rest;
  }

  regex(value: any, name?: string, match?: string): ValidationResult {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    // regex for phone number phone must be 10-11 digits

    res = {
      isValid: value.match(match) ? true : false,
      message: name + "形式が無効です",
    };

    this.respone = res;
    return res;
  }
  phone = (
    value: string,
    name?: string,
    isNumber = false
  ): ValidationResult => {
    let res: any;
    
    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      

      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    // regex for phone number phone must be 10-11 digits

    const re = /^[0-9]{10,11}$/;

    if (isNumber)
      res = {
        isValid: re.test(String(value)),
        message:
          value.length == 0
            ? name + "は必須です。"
            : name +
              "が正しくありません。ハイフンなしの数字10〜11桁で入力してください",
      };
    else
      res = {
        isValid: re.test(String(value)),
        message:
          value.length == 0
            ? name + "は必須です。"
            : name + "が正しくありません。ハイフンなしの数字10〜11桁で入力してください。",
      };
    this.respone = res;

    return res;
  };

  minLength(value: string, minLength: number): ValidationResult {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    // regex for phone number phone must be 10-11 digits

    const isValid = value.length >= minLength;
    res = {
      isValid,
      message: isValid
        ? ""
        : `This field must be at least ${minLength} characters long`,
    };

    this.respone = res;
    return res;
  }

  maxLength(value: string, maxLength: number): ValidationResult {
    let res: any;

    if (this.variation?.required && !this.respone.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const isValid = value.length <= maxLength;

    res = {
      isValid,
      message: isValid
        ? ""
        : `このフィールドの長さは最大 ${maxLength} 文字にする必要があります`,
    };

    this.respone = res;
    return res;
  }

  password(password: string): ValidationResult {
    let res: any;
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    res = {
      isValid: re.test(String(password)),
      message:
        password.length == 0
          ? msg.validation.password.required
          : msg.validation.password.invalid,
    };

    this.respone = res;
    return res;
  }

  passwordc(passwordc: string, password: any): ValidationResult {
    let res: any;
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    res = {
      isValid: re.test(String(password)) && (passwordc == password),
      message:
      passwordc != password
          ? "パスワード（確認用）が一致しません。"
          : msg.validation.password.invalid,
    };

    this.respone = res;
    return res;
  }

  passwordNotMatch(
    password: string,
    passwordConfirm: string
  ): ValidationResult {
    return {
      isValid: password == passwordConfirm,
      message: msg.validation.password.notmatch,
    };
  }

  card(card: string): ValidationResult {
    const re = /^[0-9]{15,17}$/;
    return {
      isValid: re.test(String(card)),
      message:
        card.length == 0
          ? msg.validation.card.required
          : msg.validation.card.invalid,
    };
  }

  cardCvc(cardCvc: string): ValidationResult {
    const re = /^[0-9]{3,5}$/;
    return {
      isValid: re.test(String(cardCvc)),
      message:
        cardCvc.length == 0
          ? msg.validation.card.cardCvc.required
          : msg.validation.card.cardCvc.invalid,
    };
  }

  numeric(value: any, name?: string) {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const re = /^[0-9]+$/;

    res = {
      isValid: re.test(String(value)),
      message: `${name}は数字以外入力できません。`,
    };

    this.respone = res;
    return res;
  }

  float(value: any, name?: string) {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const re = /^[0-9]*(\.[0-9]{0,2})?$/;

    res = {
      isValid: re.test(String(value)),
      message: `${name}は数字以外入力できません。`,
    };

    this.respone = res;
    return res;
  }

  email(value: string): ValidationResult {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    res = {
      isValid: re.test(String(value).toLowerCase()),
      message:
        value.length == 0
          ? msg.validation.email.required
          : msg.validation.email.invalid,
    };

    this.respone = res;
    return res;
  }

  emailValid(value: string): ValidationResult {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !value) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    res = {
      isValid: re.test(String(value).toLowerCase()),
      message: msg.validation.email.invalid,
    };

    this.respone = res;
    return res;
  }

  passwordValid(password: string): ValidationResult {
    let res: any;

    if (!this.respone?.isValid) return this.respone;

    if (!this.variation?.required && !password) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    // if password include only * then return true
    let passwordArray = password.split("");
    let count = true;

    passwordArray.forEach((item) => {
      if (item !== "*") {
        count = false;
      }
    });

    if (count) {
      res = { isValid: true, message: "" };
      this.respone = res;
      return res;
    }

    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    res = {
      isValid: re.test(String(password)),
      message:
        password.length == 0
          ? msg.validation.password.required
          : msg.validation.password.invalid,
    };

    this.respone = res;
    return res;
  }

  accept(value: boolean): ValidationResult {
    return {
      isValid: value,
      message: msg.validation.accept.required,
    };
  }

  validate(value: any, rules: string): ValidationResult {
    const ruleList = rules.split("|");
    for (let i = 0; i < ruleList.length; i++) {
      const rule = ruleList[i];
      const [ruleName, ...ruleArgs] = rule.split(":") as [
        keyof FormValidation,
        ...any[]
      ];
      if (this[ruleName]) {
        const validationResult = this[ruleName](value, ...ruleArgs);
        if (!validationResult.isValid) {
          return validationResult;
        }
      } else {
        const validationResult = { isValid: true, message: "" };
        if (!validationResult.isValid) {
          return validationResult;
        }
      }
    }
    return { isValid: true, message: "" };
  }

  checkbox(value: any, name: string): ValidationResult {
    const isValid = value.length > 0;
    return {
      isValid,
      message: isValid ? "" : name + "は必須です。",
    };
  }
}

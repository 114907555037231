import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";
import { IRegisterSubmitParams, type IRegisterService } from "@/datasource/services/register";
import { IRegisterParams } from "@/models/Register";
import { IVerifyParams, type IVerifyService } from "@services/verifyService";
import { IListLab, ISearchLabParams, type ISearchLabService } from "@/datasource/services/searchLabService";
import { ICheckExistInfoParams, type ICheckExistInfoService } from "@/datasource/services/checkExistInfo";
import { ICardParams } from "@/models/card/Card";
import { type ICardService } from "@/datasource/services/card/cardService";

export interface ICardRepository {
  addCard(params: ICardParams): Promise<IDataResponse<any>>;  
}
@injectable()
export class CardRepository implements ICardRepository {
  constructor(
    @inject(TYPES.CardService) private cardService: ICardService,   
  ) {}
  async addCard(params: ICardParams): Promise<IDataResponse<any>> {
    let res = await this.cardService.addCard(params);
    return res;
  }  
}

import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";


@injectable()
export class DepartmentRepository {
  constructor(
    @inject(TYPES.DepartmentService) private departmentService: any,
  ) {}



  async fetchs( params : any ): Promise<IDataResponse<any>> {
    return await this.departmentService.fetchs( params );
  }

  async getDepart(): Promise<IDataResponse<any>> {
    let res = await this.departmentService.getDepart();
    return res;
  }

  async createDepart(params: any,  labid: number ): Promise<IDataResponse<any>> {
    let res = await this.departmentService.createDepart(params, labid);
    return res;
  }

  async updateDepart(params: any, laboid: number, deptid: number  ): Promise<IDataResponse<any>> {
    let res = await this.departmentService.updateDepart(params, laboid, deptid);
    return res;
  }

  async deleteDepart(laboid: number, deptid: number): Promise<IDataResponse<any>> {
    let res = await this.departmentService.deleteDepart(laboid, deptid);
    return res;
  }

  async sort( ids: Object ): Promise<IDataResponse<any>> {
    const res = await this.departmentService.sort(ids);
    return res;
  }
}

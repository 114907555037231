import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FormValidation from '@/utils/validate';
export interface ILoginState <T extends object>{
  email: string;
  password: string;
  isAccept: boolean;
  isLoading: boolean;
  validateLogin: T
}
const initialState: ILoginState<any> = {
  email: '',
  password: '',
  isAccept: false,
  isLoading: false,
  validateLogin: {}
};
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setActionEmail (state, action: PayloadAction<string>) {
      state.email = action.payload;
      state.validateLogin.email = new FormValidation().email(action.payload);
    },
    setActionPassword (state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.validateLogin.password = new FormValidation().password(action.payload);
    },
    setActionIsAccept (state, action: PayloadAction<boolean>) {
      state.isAccept = action.payload;
    },
    reset (state) {    
      Object.assign(state, initialState )
      // state = initialState
    },
    setInt( state, action:  PayloadAction<any> )
    {
      Object.assign(state, action.payload )
    }
  },
});

const loginReducer = loginSlice.reducer;
const { setActionEmail, setActionPassword, setActionIsAccept, reset, setInt } = loginSlice.actions;
export { loginReducer, setActionEmail, setActionPassword, setActionIsAccept, reset, setInt };

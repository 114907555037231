import Router from "next/router"
import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { number } from "yup";
import { container } from "@/di";
import { MaterialViewModel } from "@/modelviews/setting/material";
import { promises } from "dns";
import { setLoadData  } from "@/store/setting";

import { ImplantViewModel } from "@/modelviews/setting/implant";
import { json } from "stream/consumers";
//import { injectable } from "inversify";
//@injectable()

export  class Material {



  private hooks: any;

  constructor ( hooks: any = null ) {
    if( hooks )
       this.hooks = hooks;
    else
      this.hooks = React.useContext(HookContext) as TContextHook;
  }

  public getCategory = () => {

    if( this.hooks.router.query.category )
      return this.hooks.router.query.category;

    const { fromFiledCustom } = this.hooks.getState().register;

    if( fromFiledCustom?.material_category_id )
      return fromFiledCustom?.material_category_id;

    const { categories } =  this.hooks.getState().setting ;
      return categories[0]?.id;

  }

  public getTypeMaterial =  () : { category: number, name: string, typeCate: string, items: any } =>
  {
    
    let { categories, material, metalType, manufactures  } =  this.hooks.getState().setting;
    
    const category: any  =  this.getCategory();

    if( !categories.length && (typeof window !== 'undefined') )
    {
      categories = localStorage.getItem('categoriesTemp') 
      categories = categories ? JSON.parse( categories ) : []      
    }
    
    //ImplantViewModel
    const type: any  =  categories.filter( (val : any) => val.id == category )
                                  .reduce( (cur : any, val: any) =>
                                            {
                                                if( val.is_metal )
                                                  return { ...cur, ...val, typeCate: 'metal' }

                                                if( val.type == 'implant' )
                                                  return { ...cur, ...val, typeCate: 'implant' }

                                                return { ...cur, name: val.name, typeCate: 'product' }

                                            }, {} );
                                            
    let obj: { [key : string] : any } = { items: [], last_page: 1, current_page: 1 };

    switch(type.type)
    {
      case'implant':
          if( manufactures.length )
          obj['items'] = [ ...manufactures];
          break;
      default:
          if( material[category] )
          obj = material[category];
          break;
    }

    return { category, metalType, ...type, ...obj };
  }

}


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IState {
  modals: any;
  products: any;
  productClasses: any;
  productCategories: any;
  productCategory: any;
  product: any;
  materials: any;
  manuFactures: any;
  materialCategories: any;
  currentMaterialCategory: any;
  currentProductCategory: any;
  currentProductClass: any;
}

const initialState: IState = {
  currentMaterialCategory: null,
  currentProductCategory: null,
  currentProductClass: null,
  materialCategories: null,
  manuFactures: [],
  materials: [],
  productClasses: [],
  productCategories: [],
  productCategory: {},
  products: {
    items: [],
    current_page: 1,
    last_page: 1,
    sort: {},
    filter: "",
    total: 0,
  },
  product: {
    processes: [],
    switchProducts: [],
    materials: [],
    variations: [],
    Icon: null,
    name: "",
    breakdown_flg: false,
    breakdown_numbers: 0,
    code: "",
    set_shade_flg: false,
    no_part_flg: false,
    part_breakdown_numbers: 1,
    variation_flg: false,
    point: "",
    print_bill_flg: false,
  },
  modals: {
    showIcon: false,
    showMaterial: false,
    showProcess: false,
    showSwitchProduct: false,
    showConfirmProcess: false,
  },
};

export const settingProductSlice = createSlice({
  name: "settingProduct",
  initialState,
  reducers: {
    resets(state) {
      state.products = { ...initialState.products };
      state.product = { ...initialState.product };
      state.productCategories = [...initialState.productCategories];
    },

    fetchAllProducts(state, action) {
      state.products = action.payload;
    },

    setProductSettingByCondition(state, action) {
      let { field, val } = action.payload;
      let product = { ...state.product };

      if (field === "breakdown_flg" && val === true) {
        // product.processes = [];
        // product.materials = [];
      } else {
        // product.switchProducts = [];
      }

      if (field === "variation_flg" && !val) {
        // product.variations = [];
      }

      state.product = product;
    },

    fetchProduct(state, action) {
      let data = action.payload;
      let product = state.product;
      for (let prop in product) {
        product[prop] = data[prop];
      }
      product.id = data.id;
      product.processes = data.Process.map((item: any) => ({
        ...item,
        time: item.ProductProcess.time
          ? item.ProductProcess.time
          : item.ProductCategoryProcessSetting?.time,
        point: item.ProductProcess.point
          ? item.ProductProcess.point
          : item.ProductCategoryProcessSetting?.point,
      }));
      product.switchProducts = data.ProductSwitches.map((item: any) => ({
        DesProduct: item.DesProduct,
        id: item.id,
        product_id: item.DesProduct.id,
        code: item.DesProduct.code,
        name: item.DesProduct.name,
        quantity: item.quantity,
        count_flg: item.count_flg,
      }));
      product.materials = data.Meterial.map((material: any) => ({
        ...material,
        quantity: parseInt(material.ProductMaterial.quantity) || 0,
      }));
      product.variations = data.Variation;
    },

    fetchProducts(state, action) {
      const { data, last_page, current_page, total } = action.payload;
      state.products = { items: data, current_page, last_page, total };
    },

    fetchProductClasses(state, action) {
      let data = action.payload;
      state.productClasses = data;
    },

    fetchProductCategories(state, action) {
      let data = action.payload;
      state.productCategories = data;
    },

    fetchProductCategory(state, action) {
      let data = action.payload;
      state.productCategory = { ...data };
    },

    updateProduct(state, action) {
      let data = action.payload;
      state.product = data;
    },

    resetProductCategories(state) {
      state.productCategories = [...initialState.productCategories];
    },

    resetMaterials(state) {
      state.materials = [...initialState.materials];
    },

    resetProducts(state) {
      state.products = { ...initialState.products };
    },

    fetchMaterialCategories(state, action) {
      let data = action.payload;
      state.materialCategories = data;
    },

    fetchMaterials(state, action) {
      let data = action.payload;
      state.materials = [...data];
    },

    fetchManuFactures(state, action) {
      let data = action.payload;
      state.manuFactures = [...data];
    },

    fetchCurrentMaterialCategory(state, action) {
      state.currentMaterialCategory = action.payload;
    },

    fetchCurrentProductCategory(state, action) {
      state.currentProductCategory = action.payload;
    },

    fetchCurrentProductClass(state, action) {
      state.currentProductClass = action.payload;
    },

    updateVariation(state, action) {
      let { index, prop, val } = action.payload;
      let variations = state.product.variations;
      if(!variations[index])
      { 
        return;
      }

      variations[index][prop] = val;
      state.product.variations = variations;
    },

    updateProcess(state, action) {
      let { index, prop, val } = action.payload;
      let processes = state.product.processes;
      processes[index][prop] = val;
      state.product.processes = processes;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
  },
  extraReducers: (builder) => {},
});

export default settingProductSlice;
export const settingProductReducer = settingProductSlice.reducer;
export const settingProductSliceAction = settingProductSlice.actions;

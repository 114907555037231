import { type TContextHook } from "@/context/hook";
import { setLoading, setModal, setMsg } from "@/store/global";
import { Container, inject, injectable } from "inversify";
import "reflect-metadata";
@injectable()
export class BaseViewModel {
  protected hooks: TContextHook = {} as TContextHook;
  public setHooks(hooks: TContextHook) {
    this.hooks = hooks;
  }
  public setMsg(text: string | null | undefined, status: number) {
    this.hooks.dispatch(setMsg({ text, status }));
  }
  // setLoading for repository
  protected async withLoading<T>(func: () => Promise<T>): Promise<T> {
    this.hooks.dispatch(setLoading(true));
    const result = await func();
    this.hooks.dispatch(setLoading(false));
    return result;
  }

  // setTimeout and redirect
  protected timeoutRedirect(    
    timeout: number,
    redirect: string
  ) {    
    setTimeout(() => {
      this.hooks.router.push(redirect);
    }, timeout);    
  }

  //close all modal
  protected closeAllModal() {
    this.hooks.dispatch(setModal({
      isCloseAll: true
    }));
  }

  // setLoading
  protected setLoading(isLoading: boolean) {
    this.hooks.dispatch(setLoading(isLoading));
  }
}
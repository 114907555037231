import { IZipToPrefCity } from "@/models/Prefcity";
import { IDataResponse } from "../interface/IDataResponse";

export const normalizeZipToPrefCity = (data: any): IZipToPrefCity => {
  return {
    ward : data.ward,
    zipcode: data.zipcode,    
    City: {
      id: data.City?.id,
      name: data.City?.name,
      pref_id: data.City?.pref_id,
    },  
    Pref: {
      id: data.City?.Pref?.id,
      name: data.City?.Pref?.name, 
      region_id: data.City?.Pref?.region_id
    }
  }
}
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface IOrderGroupService {
  fetchOrderGroups(params: any): Promise<IDataResponse<any>>;
  create(data: any): Promise<IDataResponse<any>>;
  update(id: number, data: any): Promise<IDataResponse<any>>;
  delete(id: number): Promise<IDataResponse<any>>;
  importCSV(data: any): Promise<IDataResponse<any>>;
}

@injectable()
export class OrderGroupService implements IOrderGroupService {
  private prefix: string;

  constructor() {
    this.prefix = "/labs/order-groups";
  }

  async fetchOrderGroups(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async create(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async update(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async importCSV(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/csv`, data);
    return response;
  }
}

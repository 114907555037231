import {
  IAuthToken,
  IUserCredentials,
  IUserQRCredentials,
  IUserUpdate,
  Profile,
} from "@models/auth";
import type { IAuthService } from "@services/interface/IAuthService";
import { IAxiosServiceResponse } from "@services/axiosService";
import { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";

@injectable()
export class AuthRepository implements IAuthRepository {
  constructor(@inject(TYPES.AuthService) private authService: any ) {}
  async login(useCredentials: IUserCredentials): Promise<IDataResponse<any>> {
    let res = await this.authService.login(useCredentials);
    return res;
  }

  async forgotPassword(params: any): Promise<IDataResponse<any>> {
    return await this.authService.forgotPassword(params);
  }

  async logOut( ): Promise<IDataResponse<any>> {
    return await this.authService.logOut(  );
  }

  async resetPassword(params: any): Promise<IDataResponse<any>> {
    return await this.authService.resetPassword(params);
  }

  async loginByQR(data: IUserQRCredentials): Promise<IDataResponse<any>> {
    let res = await this.authService.loginByQR(data);
    return res;
  }

  async getProfile(): Promise<IAxiosServiceResponse<Profile>> {
    let res = await this.authService.getProfile();
    return res;
  }

  async updateProfile(data: IUserUpdate): Promise<IAxiosServiceResponse<Profile>> {
    let res = await this.authService.updateProfile(data);
    return res;
  }
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IModal {
  modalId: string;
  isCloseAll: boolean;
  isShow: boolean;
  data? : any;
}

interface WorkingOrderLog {
  orderId: number;
  startTime: any,
  type: string | "order" | "confirm"
}
interface IState {
  [key : string]: any,
  isLoading: boolean;
  msg: any;
  modalList: IModal[];
  workingOrderLogs: Array<WorkingOrderLog>;
}
const initialState: IState = {
  isLoading: false,
  msg: {},
  modalList: [],
  workingOrderLogs: [],
};
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoading: (state, action) => {      
      state.isLoading = action.payload;
    },
    setMsg: (state, action) => {
      const { text, status } = action.payload;
      state.msg = { text, status };
    },
    clearMsg: (state, action) => {
      state.msg = {};
    },
    setModal: (state, action) => {
      const { modalId, isCloseAll, isShow, data } = action.payload;
      if (isCloseAll) {
        state.modalList = [];
      }
      if (isShow) {
        state.modalList.push({ modalId, isCloseAll, isShow, data });
      } else {
        state.modalList = state.modalList.filter((x) => x.modalId !== modalId);
      }
    },
    setVariationGlobal : (state, action) => {
      const { data, field } : any = action.payload;
      state[ String( field ) ] = data;
    },

    setWorkingTimeOrderLog : (state, action) => {
      let workingOrderLogs = [...state.workingOrderLogs];
      let {orderId, startTime, type} = action.payload;
      let index = workingOrderLogs.findIndex((item: any) => item.orderId === orderId && item.type == type)
      if(index !== -1)
      {
        let workingOrderLog = workingOrderLogs[index];
        workingOrderLog = {
          ...workingOrderLog,
          startTime: startTime,
          type: type,
        }
        workingOrderLogs[index] = {...workingOrderLog}
      }else {
        workingOrderLogs.push({
          orderId,
          startTime,
          type
        })
      }
      state.workingOrderLogs = workingOrderLogs
    }
  },
  extraReducers: (builder) => {},
});

export default globalSlice;
export const globalReducer = globalSlice.reducer;
export const { setLoading, setMsg, setModal, setVariationGlobal, setWorkingTimeOrderLog } = globalSlice.actions;

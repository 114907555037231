import { ILabInfo } from "@/models/Lab";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IState {
  branchList: ILabInfo[];
  departmentEmployee: any;
}
const initialState: IState = {
  branchList: [],
  departmentEmployee: [],
};

export const settingBranchSlice = createSlice({
  name: "settingBranch",
  initialState,
  reducers: {
    setBranchList(state, action: PayloadAction<any>) {
      state.branchList = action.payload;
    },
    setDepartmentEmployee(state, action: PayloadAction<any>) {
      state.departmentEmployee = action.payload;
    }
  }
});

export const settingBranchReducer = settingBranchSlice.reducer;
export const { setBranchList, setDepartmentEmployee } = settingBranchSlice.actions;

export enum statisticsType {
  month,
  date
}

export enum TaskModal {
  doneTask,
  scan
}

export enum taskStatusEnum {
  new = 'new',
  confirmed ='confirmed',
  doing = 'doing',
  pause = 'pause',
  completed = 'completed',
  cancelled = 'cancelled'
}

export interface ITask  {
  id: number | null,
  user_id: number | null,
  process_id: number | null,
  status: taskStatusEnum,
  total_time: string | null,
  working_time: string | null,
  total_point: string | null,
  average_time: string | null,
  done_time: string | null,
  last_start_time: string | null,
  count_order: number | null,
  product_point: number | null,
  total_product: number | null,
  min_time: number | null,
  Task?: IProcess | null;
  ProcessOrder: any | null;
}

export interface IProcess  {
  id: number | null,
  sort_order: number | null,
  admin_process_id: number | null,
  name: string | null,
  time: string | null,
  point: string | null,
  target_point: string | null,
  category_id: number | null,
  master_type: string | null,
  master_id: number | null,
  scan_order_flg: boolean | null,
  ProcessCategory?: IProcessCategory | null
}

export interface IProcessCategory  {
  id: number | null,
  name: string | null,
  type: string | null,
  master_type: string | null,
  master_id: string | null
}

export interface ITaskStatistic  {
  total_point: string | null,
  target_point_month: number | null,
  target_point_now: number | null,
  total_date: number | null,
  setting?: any | null,
  pause_time?:  any | null,
}
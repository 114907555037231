import { IDepartmentEmployee } from "@/models/setting/Department"
import _ from "lodash";

export const normalizeDepartmentEmployee = (data: any): {
  data: IDepartmentEmployee[],
} => {
  let listDepartmentEmployee: any[] = [];
  let newdata = data.map( (item: any) => { 
    if (item.Departments.length != 0) {
      listDepartmentEmployee = [...listDepartmentEmployee, ...item.Departments] 
    } 
  })
  listDepartmentEmployee = _.uniqBy(listDepartmentEmployee, 'id');
  return {
    data: listDepartmentEmployee
  };
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ICity, IPref } from "@models/Lab";
import { IZipToPrefCity } from "@/models/Prefcity";
interface IState {
  zipToPrefCity: IZipToPrefCity,
  prefList: IPref[],
  cityList: ICity[],
}
const initialState: IState = {
  zipToPrefCity: {} as IZipToPrefCity,
  prefList : [],
  cityList: [],
}
export const prefcitySlice = createSlice({
  name: "prefcity",
  initialState,
  reducers: {
    setZipToPrefCity: (state, action) => { 
      state.zipToPrefCity = action.payload;
    },
    setPrefList: (state, action) => {
      state.prefList = action.payload;
    },
    setCityList: (state, action) => {
      state.cityList = action.payload;
    }    
  },
  extraReducers: (builder) => {}
});

export default prefcitySlice;
export const globalReducer = prefcitySlice.reducer;
export const { setZipToPrefCity, setPrefList, setCityList } = prefcitySlice.actions;

import React, { MouseEventHandler } from "react";
import Image from "next/image";
import { Modal } from "@/components/Modal";
import { IModalCloseParams } from "@/models/Modal";
import { HookContext, TContextHook } from "@/context/hook";
import { container } from "@/di";
import { SettingEmployeeViewModel } from "@/modelviews/setting/employee";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setFromFiledCustomSubmit, setFromFiledCustom } from "@/store/register";
import jaJP from 'date-fns/locale/ja';
import dayjs from "dayjs";
import { useRouter } from "next/router";

interface IProps {
  imgAssets: any;
  handleModalClose: (
    modalCloseParams: IModalCloseParams
  ) => void;
  [key: string]: any;
}
export const ModalConfirmRecoverEmployee = ({
  imgAssets,
  modal,
  handleModalClose,
  ...props
}: IProps) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const employeeViewModel = container.resolve<SettingEmployeeViewModel>(
    SettingEmployeeViewModel
  );
  employeeViewModel.setHooks(hooks);
  
  const { currentEmployee } = hooks.useSelector((state: any) => state.settingEmployee );
    
  
  const intDate = currentEmployee?.recovered_at
  const router = useRouter()
  
  
  const [selectedDate, setSelectedDate] = hooks.useState( new Date() );

  React.useEffect( () => {
      
      setSelectedDate( intDate? new Date(intDate) : new Date()  )
    
  }, [intDate, hooks.router] )

  const modalId = modal.modalIds.confirmRecoverEmployee;
  const modalList = hooks.useSelector((state: any) => state.global.modalList);
  const modalData = modalList.find((item: any) => item.modalId === modalId);
  const isShow = modalData?.isShow;
  const data = modalData?.data;
  const employeeId = hooks.router.query?.id || hooks.router?.asPath.replace("/setting/employee/edit/","")
  //data?.employeeId;
  
  
  const handleRecoverEmployee = async () => {

   // if(employeeId && isShow){
      const res:any = await employeeViewModel.recoverEmployee({recovered_at: new Date(selectedDate).toLocaleString("en-US", {timeZone: "Asia/Tokyo"}) }, employeeId);
      
      if(res?.success)
      {
        hooks.dispatch( setFromFiledCustomSubmit({ value: 1, name: 'retired_flg' }))
        return hooks.router.reload()
        //return hooks.router.push(`/setting/employee/edit/${employeeId}`)
      }

    //}
  };
  return (
    <Modal
      isDefaultShow={false}
      isDefaultShowOnRoute={'/setting/employee/edit/[id]'}
      modalId={modal.modalIds.confirmRecoverEmployee}
      modalType={modal.modalTypes.confirmModal}
      modalSubType='modalAlert'
      modalTitle="Modal title"
    >
      <>
        <div className="modal-body">
          <div className="ico text_center"><Image src={imgAssets.icoExcla} alt="" /></div>
          <h4 className="text_center title f20">従業員の復職</h4>
          <p>退職済の従業員を復職します。</p>
          <div className="blockinput retirementdate dflex">
            <p>復職日</p>
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date) => {                                             
                                            setSelectedDate(date) 
                                        } }
              dateFormat="yyyy/MM/dd"
              placeholderText="yyyy/mm/dd"
              locale={jaJP}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => {
              return (
                <>
                <button 
                onClick={decreaseMonth} 
                disabled={prevMonthButtonDisabled}
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--previous" 
                aria-label="Previous Month">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span>
                </button>

                <button
                onClick={increaseMonth} 
                disabled={nextMonthButtonDisabled}
                type="button" 
                className="react-datepicker__navigation react-datepicker__navigation--next" 
                aria-label="Next Month">
                  <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
                </button>
              
                <div className="react-datepicker__header ">
                  <div className="react-datepicker__current-month"> {`${date.getFullYear()}年 ${date.getMonth() + 1}月`}</div>
                </div>
                </>
              )}}
            />
          </div>
        </div>
        <div className="modal-footer dflex">
          <button className="btn btn-outline-secondary minw140" type="button" onClick={(e) => {
              handleModalClose({                
                modalId: modal.modalIds.confirmRecoverEmployee,
                isCloseAll: false,                
              });              
            }}>キャンセル</button>
          <button className="btn btn-danger minw140" type="button" onClick={handleRecoverEmployee}>復職</button>
        </div>
      </>
    </Modal>
  );
};

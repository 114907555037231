import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";




@injectable()
export class PartnerRepository {
  constructor(
    @inject(TYPES.PartnerService) private partnerService: any
  ) {}
  
  async fetchDefaultSort(): Promise<IDataResponse<any>> {
    return await this.partnerService.fetchDefaultSort();
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    let res = await this.partnerService.fetchAll(params);
    return res;
  }

  async getclinics(params: any): Promise<IDataResponse<any>> {
    let res = await this.partnerService.getclinics(params);
    return res;
  }

  async fetch(ids: number): Promise<IDataResponse<any>> {
    let res = await this.partnerService.fetch(ids);
    return res;
  }

  async create( id: number,data: any): Promise<IDataResponse<any>> {
    let res = await this.partnerService.create( id, data);
    return res;
  }

  async update(id: number, params: any): Promise<IDataResponse<any>> {
    let res = await this.partnerService.update( id, params);
    return res;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    let res = await this.partnerService.delete(id);
    return res;
  }

  async sort(ids: object ): Promise<IDataResponse<any>> {
    let res = await this.partnerService.sort(ids);
    return res;
  }

}

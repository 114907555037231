
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { IPermissionParams } from "@/models/setting/Permission";

export interface ISettingPermissionService {
  getAllPermission(params?: any): Promise<IDataResponse<any>>;
  createPermission(params: IPermissionParams): Promise<IDataResponse<any>>;  
}

@injectable()
export class SettingPermissionService implements ISettingPermissionService {
  private prefix: string;
  constructor() {
    this.prefix = '/permissions';
  }
  async getAllPermission(params?: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {params});
    return response;
  }
  async createPermission(params: IPermissionParams): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, {params});
    return response;
  }  
}
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface IStatisticWeekPointService {
  fetchAll(params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class StatisticWeekPointService implements IStatisticWeekPointService {
  private prefix: string;

  constructor() {
    this.prefix = "/labs/statistic/week";
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }
}


import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import { IRegisterParams, IRegisterSubmitField } from "@models/Register";

export interface IRegisterService {
  register(params: IRegisterParams): Promise<IDataResponse<any>>;  
  registerSubmit(params: IRegisterSubmitParams): Promise<IDataResponse<any>>;
  phoneInfo(params: IRegisterSubmitParams): Promise<IDataResponse<any>>;
}
export interface IRegisterSubmitParams extends Omit<IRegisterSubmitField, 'lab_fax'>{}
@injectable()
export class RegisterService implements IRegisterService {
  private prefix: string;
  constructor() {
    this.prefix = '/auth';
  }
  
  async phoneInfo(params: any): Promise<IDataResponse<any>> {
    return await axiosService.get(`labs/check-exist-info`, {params});    
  }

  async register(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/register`, params);    
    return response;
  }
  async registerSubmit(params: IRegisterSubmitParams): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/register/submit-lab`, params);
    return response;
  }
}
import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface IOrderService {
  saveOrders(data: Array<any>): Promise<IDataResponse<any>>;
  saveOrderImages(data: Array<any>): Promise<IDataResponse<any>>;
  fetchOrderByCode(code: string): Promise<IDataResponse<any>>;
  fetchOrders(params: any): Promise<IDataResponse<any>>;
  fetchOrder(id: number, params: any): Promise<IDataResponse<any>>;
  confirmOrder(id: number, data: any): Promise<IDataResponse<any>>;
  fetchImages(orderId: number, params: any): Promise<IDataResponse<any>>;
  createOrder(data: any): Promise<IDataResponse<any>>;
  updateOrder(id: number, data: any): Promise<IDataResponse<any>>;
  deleteOrder(id: number): Promise<IDataResponse<any>>;

  bulkOrders(data: Array<any>): Promise<IDataResponse<any>>;
  cloneOrder(data: Array<any>): Promise<IDataResponse<any>>;

  fetchOrderParents(id: number, params: any): Promise<IDataResponse<any>>;
  deleteOrderParent(id: number, params: any): Promise<IDataResponse<any>>;
  addOrderParent(id: number, data: any): Promise<IDataResponse<any>>;

  getOrders(params: any): Promise<IDataResponse<any>>;
  getOrderForChilds(params: any): Promise<IDataResponse<any>>;
  removeOwnOrder(data: any): Promise<IDataResponse<any>>;
  fetchRemakes(params: any): Promise<IDataResponse<any>>;
  updateRemakes(params: any): Promise<IDataResponse<any>>;
  saveOrderImagesV2(data: Array<any>): Promise<IDataResponse<any>>;
  removeImages(id: number, data: Array<any>): Promise<IDataResponse<any>>;
  removeHold(id: any): Promise<IDataResponse<any>>;
  getEditable(id: any): Promise<IDataResponse<any>>;
  setCurrentOwner(data : any) : Promise<IDataResponse<any>>;
  getServerTime() : Promise<IDataResponse<any>>;
}

@injectable()
export class OrderService implements IOrderService {
  private prefix: string;

  constructor() {
    this.prefix = "/labs/orders";
  }
  //set current owner

  async getServerTime(): Promise<IDataResponse<any>> {
    return await axiosService.get(`/get-server-time`);
  }
  
  async setCurrentOwner(data: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/hold`, data );
  }

  async fetchRemakes(params: any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/remake`, {params});
  }

  async updateRemakes(params: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/remake`, params);
  }

  async removeOwnOrder(data: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/remove-own`, data);
  }

  async removeHold(id: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/${id}/remove-hold`);
  }

  async getEditable(id: any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/${id}/check-editable`);
  }

  async saveOrderImages(data: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/images`, data);
  }

  async saveOrderImagesV2(data: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/images/v2`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }

  // data: {ids: Array}
  async removeImages(id: number,  data: any): Promise<IDataResponse<any>> {
    return await axiosService.put(`${this.prefix}/${id}/images`, data);
  }

  async bulkOrders(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefix}/bulk-create`,
      data
    );
    return response;
  }

  async cloneOrder(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/clone`, data);
    return response;
  }

  async saveOrders(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async fetchOrderByCode(code: string): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/code/${code}`);
    return response;
  }

  async fetchOrders(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async fetchImages(orderId: any, params: any): Promise<IDataResponse<any>> {
    return await axiosService.get(`${this.prefix}/${orderId}/images`, {
      params,
    });
  }

  async fetchOrder(id: number, params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}`, {
      params: params,
    });
    return response;
  }

  async fetchOrderParents(
    id: number,
    params: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/${id}/parent`, {
      params: params,
    });
    return response;
  }

  async deleteOrderParent(
    id: number,
    params: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}/parent`, {
      params: params,
    });
    return response;
  }

  async addOrderParent(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefix}/${id}/parent`,
      data
    );
    return response;
  }

  async confirmOrder(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefix}/${id}/confirm`,
      data
    );
    return response;
  }

  async updateOrder(id: number, data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.put(`${this.prefix}/${id}`, data);
    return response;
  }

  async deleteOrder(id: number): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }

  async createOrder(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}`, data);
    return response;
  }

  async getOrders(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}`, {
      params: params,
    });
    return response;
  }

  async getOrderForChilds(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`/labs/orders-for-child`, {
      params: params,
    });
    return response;
  }
}
